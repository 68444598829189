import { ref, computed, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import ability from "@/libs/acl/ability";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { debounce } from "lodash";

export default function useList() {
  const { t } = useI18nUtils();
  const trans = {
    "messages.error.server_error": t("messages.error.server_error"),
  };
  const refListTable = ref(null);

  const toast = useToast();

  store.dispatch("tenant/getAll");
  store.dispatch("type_alarm/getAll");

  const tenantsOptions = computed(() => store.getters["tenant/getSelectedItems"]);
  const tenantsFilter = ref(null);

  const typesAlarmsOptions = computed(() => store.getters["type_alarm/getSelectedItems"]);
  const typesAlarmsFilter = ref(null);

  const tableColumns = [
    { label: "schedule_alarm.name", key: "name", sortable: true },
    { label: "tenants.title", key: "tenants", sortable: true,
      formatter: (value, key, item) => {
        if (item.tenants) return [...new Set(item.tenants.map(item => { return item.name }))].join(', ')
        return ""
      }
    },
    { label: "types_alarms.title", key: "types_alarms", sortable: true,
      formatter: (value, key, item) => {
        if (item.types_alarms) return [...new Set(item.types_alarms.map(item => { return item.description }))].join(', ')
        return ""
      }
    },
    {
      label: 'date.created_at', key: 'created_at', sortable: true, formatter: 'formatDateAssigned',
    },
    {
      label: 'date.updated_at', key: 'updated_at', sortable: true, formatter: 'formatDateAssigned',
    },
  ];
  if (
    ability.can("update", "ScheduleAlarm") ||
    ability.can("delete", "ScheduleAlarm")
  ) {
    tableColumns.splice(0, 0, { label: "actions.name", key: "actions" });
  }

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });

  const fetchList = (ctx, callback) => {
    store
      .dispatch("schedule_alarm/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        tenants: tenantsFilter.value,
        types_alarms: typesAlarmsFilter.value
      })
      .then((response) => {
        callback(response.data);
        totalList.value = response.meta.total;
      })
      .catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: trans["messages.error.server_error"],
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };

  const refetchData = () => {
    refListTable.value.refresh();
  };

  watch([ tenantsFilter, typesAlarmsFilter, currentPage, perPage ], () => {
    refetchData();
  });

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    tenantsOptions,
    tenantsFilter,
    typesAlarmsOptions,
    typesAlarmsFilter,
    refetchData,
  };
}
