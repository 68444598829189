<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0 text-capitalize">
          {{
            isAdd
              ? `${$t("actions.add")} ${$tc("schedule_alarm.title", 1)}`
              : `${$t("actions.edit")} ${$tc("schedule_alarm.title", 1)}`
          }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <div class="p-1">
        <!-- BODY -->
        <validation-observer
          ref="refFormObserver"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('schedule_alarm.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="itemData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="tenants">
            <b-form-group
              class="text-capitalize"
              :label="$tc('tenants.title', 1)"
              label-for="tenants"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.tenants"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tenantsOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="tenants"
                multiple
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="typeAlarms">
            <b-form-group
              class="text-capitalize"
              :label="$tc('types_alarms.title', 2)"
              label-for="typeAlarms"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.types_alarms"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typesAlarmsOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="typeAlarms"
                multiple
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-tabs content-class="w-100" >
            <b-tab
              v-for="(weekday, index) in weekdays"
              :key="index"
              :title="$t(`alarm_views.fields.periodicity.days.${weekday.text}`)"
            >
              <table class="table table-sm w-75 mb-2">
                <tr>
                  <th>{{ $t("alarm_views.fields.periodicity.from") }}</th>
                  <th>{{ $t("alarm_views.fields.periodicity.to") }}</th>
                  <th></th>
                </tr>
                <tr v-for="(dt, dt_index) in weekday.data" :key="dt_index">
                  <td>
                    <input
                      type="time"
                      :name="`time-start-${index}-${dt_index}`"
                      v-model="weekday.data[dt_index].start_at"
                    />
                  </td>
                  <td>
                    <input
                      type="time"
                      :name="`time-end-${index}-${dt_index}`"
                      v-model="weekday.data[dt_index].end_at"
                    />
                  </td>
                  <td>
                    <b-button
                      variant="danger"
                      @click="removeDt(index, dt_index)"
                      size="sm"
                    >
                      {{ $t("alarm_views.fields.periodicity.btns.delete") }}
                    </b-button>
                  </td>
                </tr>
              </table>

              <div class="d-flex justify-content-end">
                <b-button variant="success" @click="addDt(index)" size="sm">
                  {{ $t("alarm_views.fields.periodicity.btns.add") }}
                </b-button>
              </div>
            </b-tab>

            <p class="text-danger" v-if="showWeekdaysValidationErrors">
              {{ $t("alarm_views.fields.periodicity.messages.invalid_schedule") }}
            </p>
          </b-tabs>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              @click="onSubmit"
              type="button"
            >
              {{ $t("actions.save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{ $t("actions.cancel") }}
            </b-button>
          </div>
        </validation-observer>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BTime,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref, computed, reactive } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    BTime,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    tenantsOptions: {
      type: Array,
      required: true,
    },
    typesAlarmsOptions: {
      type: Array,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {

    const { t } = useI18nUtils();
    const showWeekdaysValidationErrors = ref(false);
    const trans = {
      "messages.edit_save.successfully_created": t(
        "messages.edit_save.successfully_created"
      ),
      "messages.edit_save.updated_successfully": t(
        "messages.edit_save.updated_successfully"
      ),
    };
    const blankData = {
      tenants: null,
      types_alarms: null,
      datetimes: null,
      name: "",
    };
    const toast = useToast();
    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const onSubmit = async () => {
      let dispatch;

      const validation = await refFormObserver.value.validate();

      const weekdaysValidation = validateDateTimes();

      showWeekdaysValidationErrors.value = !weekdaysValidation;

      if (validation && weekdaysValidation) {

        const weekdaysData = Object.values(weekdays)
          .filter(wkd => (wkd.data[0] ? { ...wkd.data[0] } : false))
          .map(wkd => (wkd.data.map(_wkd => ({ ..._wkd }))))
          .flat();

        const data = {
          name: itemData.value.name,
          tenants: itemData.value.tenants,
          types_alarms: itemData.value.types_alarms,
          datetimes: weekdaysData
        };

        if (props.isAdd) {
          dispatch = store.dispatch("schedule_alarm/add", data);
        } else {
          const req = {
            id: itemData.value.id,
            params: data,
          };
          dispatch = store.dispatch("schedule_alarm/edit", req);
        }
        dispatch
          .then((response) => {
            if (response.success) {
              toast({
                component: ToastificationContent,
                props: {
                  title: response.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              emit("refetch-data");
              emit("update:is-sidebar-active", false);
            } else {
              toast({
                component: ToastificationContent,
                props: {
                  title: response.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch((response) => {
            toast({
              component: ToastificationContent,
              props: {
                title: response.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    };

    const addDt = (weekday) => {
      weekdays[weekday].data.push({
        weekday,
        start_at: null,
        end_at: null,
      });
    };

    const removeDt = (weekday, index) => {
      weekdays[weekday].data.splice(index, 1);
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);
    const loadData = () => {
      for (let index = 0; index < 7; index++) {
        weekdays[index].data = []
      }
      if (!props.isAdd) {
        itemData.value = props.itemEdit;
        itemData.value.tenants = props.itemEdit.tenants.map(tenant => (tenant.id));
        itemData.value.types_alarms = props.itemEdit.types_alarms.map(type_alarm => (type_alarm.id));
        itemData.value.datetimes.forEach(wkd => weekdays[wkd.weekday].data.push(wkd));
      }
    };

    const weekdays = reactive({
      0: { text: "sun", data: [] },
      1: { text: "mon", data: [] },
      2: { text: "tue", data: [] },
      3: { text: "wed", data: [] },
      4: { text: "thu", data: [] },
      5: { text: "fri", data: [] },
      6: { text: "sat", data: [] },
    });

    const validateDateTimes = () => {
      let valid = false;

      for (const key in weekdays) {
        const wkday = weekdays[key];
        if (wkday.data.length > 0) {
          const invalid = wkday.data.filter(item => {
            const start = !item.start_at ? -1 : parseInt(item.start_at.replace(':', ''));
            const end = !item.end_at ? -1 : parseInt(item.end_at.replace(':', ''));

            return start === -1 || end === -1;
          });
          valid = invalid.length === 0;
        }
      }

      return valid;
    }

    return {
      itemData,
      onSubmit,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
      weekdays,
      addDt,
      removeDt,
      showWeekdaysValidationErrors,
    };
  },
};
</script>